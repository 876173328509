export const albums_data = {
   "hdr": {
      "name": "hdr",
      "photos": [
         {
            "faces": [],
            "height": 2000,
            "name": "DSC-0464.jpg",
            "slug": "dsc-0464-jpg",
            "src": "/static/_gallery/albums/hdr/1325x2000_dsc-0464-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hdr/1024x1545_dsc-0464-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hdr/1325x2000_dsc-0464-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hdr/500x754_dsc-0464-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hdr/800x1207_dsc-0464-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hdr/500x754_dsc-0464-jpg.jpg",
            "width": 1325
         },
         {
            "faces": [],
            "height": 2001,
            "name": "DSC-0482.jpg",
            "slug": "dsc-0482-jpg",
            "src": "/static/_gallery/albums/hdr/1325x2001_dsc-0482-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hdr/1024x1546_dsc-0482-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hdr/1325x2001_dsc-0482-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hdr/500x755_dsc-0482-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hdr/800x1208_dsc-0482-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hdr/500x755_dsc-0482-jpg.jpg",
            "width": 1325
         },
         {
            "faces": [],
            "height": 1325,
            "name": "DSC-0491.jpg",
            "slug": "dsc-0491-jpg",
            "src": "/static/_gallery/albums/hdr/1600x1060_dsc-0491-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hdr/1024x678_dsc-0491-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hdr/1600x1060_dsc-0491-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hdr/500x331_dsc-0491-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hdr/800x530_dsc-0491-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hdr/500x331_dsc-0491-jpg.jpg",
            "width": 2000
         },
         {
            "faces": [],
            "height": 1356,
            "name": "DSC-0762And8more-tonemapped.jpg",
            "slug": "dsc-0762and8more-tonemapped-jpg",
            "src": "/static/_gallery/albums/hdr/1600x1059_dsc-0762and8more-tonemapped-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hdr/1024x678_dsc-0762and8more-tonemapped-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hdr/1600x1059_dsc-0762and8more-tonemapped-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hdr/500x331_dsc-0762and8more-tonemapped-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hdr/800x529_dsc-0762and8more-tonemapped-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hdr/500x331_dsc-0762and8more-tonemapped-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1356,
            "name": "DSC-0915And8more-tonemapped.jpg",
            "slug": "dsc-0915and8more-tonemapped-jpg",
            "src": "/static/_gallery/albums/hdr/1600x1059_dsc-0915and8more-tonemapped-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hdr/1024x678_dsc-0915and8more-tonemapped-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hdr/1600x1059_dsc-0915and8more-tonemapped-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hdr/500x331_dsc-0915and8more-tonemapped-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hdr/800x529_dsc-0915and8more-tonemapped-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hdr/500x331_dsc-0915and8more-tonemapped-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1355,
            "name": "DSC-1446And8more-tonemapped.jpg",
            "slug": "dsc-1446and8more-tonemapped-jpg",
            "src": "/static/_gallery/albums/hdr/1600x1058_dsc-1446and8more-tonemapped-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hdr/1024x677_dsc-1446and8more-tonemapped-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hdr/1600x1058_dsc-1446and8more-tonemapped-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hdr/500x330_dsc-1446and8more-tonemapped-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hdr/800x529_dsc-1446and8more-tonemapped-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hdr/500x330_dsc-1446and8more-tonemapped-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1353,
            "name": "DSC-1455-6-7-tonemapped.jpg",
            "slug": "dsc-1455-6-7-tonemapped-jpg",
            "src": "/static/_gallery/albums/hdr/1600x1057_dsc-1455-6-7-tonemapped-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hdr/1024x676_dsc-1455-6-7-tonemapped-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hdr/1600x1057_dsc-1455-6-7-tonemapped-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hdr/500x330_dsc-1455-6-7-tonemapped-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hdr/800x528_dsc-1455-6-7-tonemapped-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hdr/500x330_dsc-1455-6-7-tonemapped-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1356,
            "name": "DSC-1482-3-4-tonemapped3.jpg",
            "slug": "dsc-1482-3-4-tonemapped3-jpg",
            "src": "/static/_gallery/albums/hdr/1600x1059_dsc-1482-3-4-tonemapped3-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hdr/1024x678_dsc-1482-3-4-tonemapped3-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hdr/1600x1059_dsc-1482-3-4-tonemapped3-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hdr/500x331_dsc-1482-3-4-tonemapped3-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hdr/800x529_dsc-1482-3-4-tonemapped3-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hdr/500x331_dsc-1482-3-4-tonemapped3-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1353,
            "name": "DSC-2726-7-8-tonemapped.jpg",
            "slug": "dsc-2726-7-8-tonemapped-jpg",
            "src": "/static/_gallery/albums/hdr/1600x1057_dsc-2726-7-8-tonemapped-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hdr/1024x676_dsc-2726-7-8-tonemapped-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hdr/1600x1057_dsc-2726-7-8-tonemapped-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hdr/500x330_dsc-2726-7-8-tonemapped-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hdr/800x528_dsc-2726-7-8-tonemapped-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hdr/500x330_dsc-2726-7-8-tonemapped-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1356,
            "name": "DSC-6916And8more-tonemapped.jpg",
            "slug": "dsc-6916and8more-tonemapped-jpg",
            "src": "/static/_gallery/albums/hdr/1600x1059_dsc-6916and8more-tonemapped-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hdr/1024x678_dsc-6916and8more-tonemapped-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hdr/1600x1059_dsc-6916and8more-tonemapped-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hdr/500x331_dsc-6916and8more-tonemapped-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hdr/800x529_dsc-6916and8more-tonemapped-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hdr/500x331_dsc-6916and8more-tonemapped-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1356,
            "name": "DSC-7009-10-11-tonemapped.jpg",
            "slug": "dsc-7009-10-11-tonemapped-jpg",
            "src": "/static/_gallery/albums/hdr/1600x1059_dsc-7009-10-11-tonemapped-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hdr/1024x678_dsc-7009-10-11-tonemapped-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hdr/1600x1059_dsc-7009-10-11-tonemapped-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hdr/500x331_dsc-7009-10-11-tonemapped-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hdr/800x529_dsc-7009-10-11-tonemapped-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hdr/500x331_dsc-7009-10-11-tonemapped-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 683,
            "name": "DSC-7612.jpg",
            "slug": "dsc-7612-jpg",
            "src": "/static/_gallery/albums/hdr/1024x683_dsc-7612-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hdr/1024x683_dsc-7612-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hdr/1024x683_dsc-7612-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hdr/500x333_dsc-7612-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hdr/800x533_dsc-7612-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hdr/500x333_dsc-7612-jpg.jpg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 1529,
            "name": "P1010803-4-5-6-7-8-9-tonemapped.jpg",
            "slug": "p1010803-4-5-6-7-8-9-tonemapped-jpg",
            "src": "/static/_gallery/albums/hdr/1600x1194_p1010803-4-5-6-7-8-9-tonemapped-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hdr/1024x764_p1010803-4-5-6-7-8-9-tonemapped-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hdr/1600x1194_p1010803-4-5-6-7-8-9-tonemapped-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hdr/500x373_p1010803-4-5-6-7-8-9-tonemapped-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hdr/800x597_p1010803-4-5-6-7-8-9-tonemapped-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hdr/500x373_p1010803-4-5-6-7-8-9-tonemapped-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 2048,
            "name": "P1010817-18-19-20-21-22-23-tonemapped.jpg",
            "slug": "p1010817-18-19-20-21-22-23-tonemapped-jpg",
            "src": "/static/_gallery/albums/hdr/1537x2048_p1010817-18-19-20-21-22-23-tonemapped-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hdr/1024x1364_p1010817-18-19-20-21-22-23-tonemapped-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hdr/1537x2048_p1010817-18-19-20-21-22-23-tonemapped-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hdr/500x666_p1010817-18-19-20-21-22-23-tonemapped-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hdr/800x1065_p1010817-18-19-20-21-22-23-tonemapped-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hdr/500x666_p1010817-18-19-20-21-22-23-tonemapped-jpg.jpg",
            "width": 1537
         },
         {
            "faces": [],
            "height": 2048,
            "name": "P1030442-3-4-tonemapped.jpg",
            "slug": "p1030442-3-4-tonemapped-jpg",
            "src": "/static/_gallery/albums/hdr/1534x2048_p1030442-3-4-tonemapped-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hdr/1024x1367_p1030442-3-4-tonemapped-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hdr/1534x2048_p1030442-3-4-tonemapped-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hdr/500x667_p1030442-3-4-tonemapped-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hdr/800x1068_p1030442-3-4-tonemapped-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hdr/500x667_p1030442-3-4-tonemapped-jpg.jpg",
            "width": 1534
         }
      ],
      "slug": "hdr",
      "src": "/static/_gallery/albums/hdr/500x754_dsc-0464-jpg.jpg"
   },
   "iceland-2010": {
      "name": "iceland-2010",
      "photos": [
         {
            "faces": [],
            "height": 563,
            "name": "DSC-1683-1.jpg",
            "slug": "dsc-1683-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x563_dsc-1683-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-1683-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-1683-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x281_dsc-1683-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x450_dsc-1683-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x281_dsc-1683-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 563,
            "name": "DSC-1760-1.jpg",
            "slug": "dsc-1760-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x563_dsc-1760-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-1760-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-1760-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x281_dsc-1760-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x450_dsc-1760-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x281_dsc-1760-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 562,
            "name": "DSC-1785-1.jpg",
            "slug": "dsc-1785-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x562_dsc-1785-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x562_dsc-1785-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x562_dsc-1785-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x281_dsc-1785-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x449_dsc-1785-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x281_dsc-1785-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 1000,
            "name": "DSC-1875-1.jpg",
            "slug": "dsc-1875-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/563x1000_dsc-1875-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/563x1000_dsc-1875-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/563x1000_dsc-1875-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x888_dsc-1875-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/563x1000_dsc-1875-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x888_dsc-1875-1-jpg.jpg",
            "width": 563
         },
         {
            "faces": [],
            "height": 1000,
            "name": "DSC-1948-1.jpg",
            "slug": "dsc-1948-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x1000_dsc-1948-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x1000_dsc-1948-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x1000_dsc-1948-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x500_dsc-1948-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x800_dsc-1948-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x500_dsc-1948-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 1000,
            "name": "DSC-1990-1.jpg",
            "slug": "dsc-1990-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/664x1000_dsc-1990-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-1990-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-1990-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x753_dsc-1990-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-1990-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x753_dsc-1990-1-jpg.jpg",
            "width": 664
         },
         {
            "faces": [],
            "height": 563,
            "name": "DSC-2028-1.jpg",
            "slug": "dsc-2028-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x563_dsc-2028-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-2028-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-2028-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x281_dsc-2028-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x450_dsc-2028-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x281_dsc-2028-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 563,
            "name": "DSC-2091-1.jpg",
            "slug": "dsc-2091-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x563_dsc-2091-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-2091-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-2091-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x281_dsc-2091-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x450_dsc-2091-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x281_dsc-2091-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 1000,
            "name": "DSC-2111-1.jpg",
            "slug": "dsc-2111-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x1000_dsc-2111-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x1000_dsc-2111-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x1000_dsc-2111-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x500_dsc-2111-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x800_dsc-2111-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x500_dsc-2111-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 563,
            "name": "DSC-2122-1.jpg",
            "slug": "dsc-2122-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x563_dsc-2122-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-2122-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-2122-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x281_dsc-2122-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x450_dsc-2122-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x281_dsc-2122-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 664,
            "name": "DSC-2130-1.jpg",
            "slug": "dsc-2130-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x664_dsc-2130-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x664_dsc-2130-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x664_dsc-2130-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x332_dsc-2130-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x531_dsc-2130-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x332_dsc-2130-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 563,
            "name": "DSC-2174-1.jpg",
            "slug": "dsc-2174-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x563_dsc-2174-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-2174-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-2174-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x281_dsc-2174-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x450_dsc-2174-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x281_dsc-2174-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 1000,
            "name": "DSC-2178-1.jpg",
            "slug": "dsc-2178-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/664x1000_dsc-2178-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-2178-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-2178-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x753_dsc-2178-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-2178-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x753_dsc-2178-1-jpg.jpg",
            "width": 664
         },
         {
            "faces": [],
            "height": 563,
            "name": "DSC-2216-1.jpg",
            "slug": "dsc-2216-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x563_dsc-2216-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-2216-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-2216-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x281_dsc-2216-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x450_dsc-2216-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x281_dsc-2216-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 664,
            "name": "DSC-2238-1.jpg",
            "slug": "dsc-2238-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x664_dsc-2238-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x664_dsc-2238-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x664_dsc-2238-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x332_dsc-2238-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x531_dsc-2238-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x332_dsc-2238-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 563,
            "name": "DSC-2272-1.jpg",
            "slug": "dsc-2272-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x563_dsc-2272-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-2272-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-2272-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x281_dsc-2272-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x450_dsc-2272-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x281_dsc-2272-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 1000,
            "name": "DSC-2455-1.jpg",
            "slug": "dsc-2455-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/664x1000_dsc-2455-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-2455-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-2455-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x753_dsc-2455-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-2455-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x753_dsc-2455-1-jpg.jpg",
            "width": 664
         },
         {
            "faces": [],
            "height": 1000,
            "name": "DSC-2495-1.jpg",
            "slug": "dsc-2495-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/664x1000_dsc-2495-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-2495-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-2495-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x753_dsc-2495-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-2495-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x753_dsc-2495-1-jpg.jpg",
            "width": 664
         },
         {
            "faces": [],
            "height": 664,
            "name": "DSC-2619-1.jpg",
            "slug": "dsc-2619-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x664_dsc-2619-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x664_dsc-2619-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x664_dsc-2619-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x332_dsc-2619-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x531_dsc-2619-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x332_dsc-2619-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 1000,
            "name": "DSC-2815-1.jpg",
            "slug": "dsc-2815-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/664x1000_dsc-2815-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-2815-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-2815-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x753_dsc-2815-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-2815-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x753_dsc-2815-1-jpg.jpg",
            "width": 664
         },
         {
            "faces": [],
            "height": 1000,
            "name": "DSC-2831-1.jpg",
            "slug": "dsc-2831-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/664x1000_dsc-2831-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-2831-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-2831-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x753_dsc-2831-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-2831-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x753_dsc-2831-1-jpg.jpg",
            "width": 664
         },
         {
            "faces": [],
            "height": 563,
            "name": "DSC-2974-1.jpg",
            "slug": "dsc-2974-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x563_dsc-2974-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-2974-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-2974-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x281_dsc-2974-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x450_dsc-2974-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x281_dsc-2974-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 563,
            "name": "DSC-2978-1.jpg",
            "slug": "dsc-2978-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x563_dsc-2978-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-2978-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-2978-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x281_dsc-2978-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x450_dsc-2978-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x281_dsc-2978-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 1000,
            "name": "DSC-3084-1.jpg",
            "slug": "dsc-3084-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x1000_dsc-3084-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x1000_dsc-3084-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x1000_dsc-3084-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x500_dsc-3084-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x800_dsc-3084-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x500_dsc-3084-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 326,
            "name": "DSC-3141-1.jpg",
            "slug": "dsc-3141-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x326_dsc-3141-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x326_dsc-3141-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x326_dsc-3141-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x163_dsc-3141-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x260_dsc-3141-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x163_dsc-3141-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 300,
            "name": "DSC-3174-1.jpg",
            "slug": "dsc-3174-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x300_dsc-3174-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x300_dsc-3174-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x300_dsc-3174-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x150_dsc-3174-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x240_dsc-3174-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x150_dsc-3174-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 563,
            "name": "DSC-3273-1.jpg",
            "slug": "dsc-3273-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x563_dsc-3273-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-3273-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-3273-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x281_dsc-3273-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x450_dsc-3273-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x281_dsc-3273-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 177,
            "name": "DSC-3490-1.jpg",
            "slug": "dsc-3490-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x177_dsc-3490-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x177_dsc-3490-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x177_dsc-3490-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x88_dsc-3490-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x141_dsc-3490-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x88_dsc-3490-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 563,
            "name": "DSC-3619-1.jpg",
            "slug": "dsc-3619-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x563_dsc-3619-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-3619-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-3619-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x281_dsc-3619-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x450_dsc-3619-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x281_dsc-3619-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 563,
            "name": "DSC-3816-1.jpg",
            "slug": "dsc-3816-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x563_dsc-3816-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-3816-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-3816-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x281_dsc-3816-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x450_dsc-3816-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x281_dsc-3816-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 1000,
            "name": "DSC-3896-1.jpg",
            "slug": "dsc-3896-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/664x1000_dsc-3896-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-3896-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-3896-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x753_dsc-3896-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-3896-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x753_dsc-3896-1-jpg.jpg",
            "width": 664
         },
         {
            "faces": [],
            "height": 563,
            "name": "DSC-3906-1.jpg",
            "slug": "dsc-3906-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x563_dsc-3906-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-3906-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-3906-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x281_dsc-3906-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x450_dsc-3906-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x281_dsc-3906-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 563,
            "name": "DSC-3909-1.jpg",
            "slug": "dsc-3909-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x563_dsc-3909-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-3909-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x563_dsc-3909-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x281_dsc-3909-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x450_dsc-3909-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x281_dsc-3909-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 1000,
            "name": "DSC-3943-1.jpg",
            "slug": "dsc-3943-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/664x1000_dsc-3943-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-3943-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-3943-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x753_dsc-3943-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/664x1000_dsc-3943-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x753_dsc-3943-1-jpg.jpg",
            "width": 664
         },
         {
            "faces": [],
            "height": 562,
            "name": "DSC-4275-1.jpg",
            "slug": "dsc-4275-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x562_dsc-4275-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x562_dsc-4275-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x562_dsc-4275-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x281_dsc-4275-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x449_dsc-4275-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x281_dsc-4275-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 667,
            "name": "DSC-4481-1.jpg",
            "slug": "dsc-4481-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x667_dsc-4481-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x667_dsc-4481-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x667_dsc-4481-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x333_dsc-4481-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x533_dsc-4481-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x333_dsc-4481-1-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 1000,
            "name": "DSC-4623-1.jpg",
            "slug": "dsc-4623-1-jpg",
            "src": "/static/_gallery/albums/iceland-2010/1000x1000_dsc-4623-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2010/1000x1000_dsc-4623-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2010/1000x1000_dsc-4623-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2010/500x500_dsc-4623-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2010/800x800_dsc-4623-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2010/500x500_dsc-4623-1-jpg.jpg",
            "width": 1000
         }
      ],
      "slug": "iceland-2010",
      "src": "/static/_gallery/albums/iceland-2010/500x281_dsc-1683-1-jpg.jpg"
   },
   "iceland-2015": {
      "name": "iceland-2015",
      "photos": [
         {
            "faces": [],
            "height": 1356,
            "name": "DSC-6022-3-4-tonemapped.jpg",
            "slug": "dsc-6022-3-4-tonemapped-jpg",
            "src": "/static/_gallery/albums/iceland-2015/1600x1059_dsc-6022-3-4-tonemapped-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2015/1024x678_dsc-6022-3-4-tonemapped-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2015/1600x1059_dsc-6022-3-4-tonemapped-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2015/500x331_dsc-6022-3-4-tonemapped-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2015/800x529_dsc-6022-3-4-tonemapped-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2015/500x331_dsc-6022-3-4-tonemapped-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 2048,
            "name": "DSC-6252-3-4-tonemapped.jpg",
            "slug": "dsc-6252-3-4-tonemapped-jpg",
            "src": "/static/_gallery/albums/iceland-2015/1356x2048_dsc-6252-3-4-tonemapped-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2015/1024x1546_dsc-6252-3-4-tonemapped-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2015/1356x2048_dsc-6252-3-4-tonemapped-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2015/499x755_dsc-6252-3-4-tonemapped-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2015/800x1208_dsc-6252-3-4-tonemapped-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2015/499x755_dsc-6252-3-4-tonemapped-jpg.jpg",
            "width": 1356
         },
         {
            "faces": [],
            "height": 1410,
            "name": "DSC-6384And8more-tonemapped.jpg",
            "slug": "dsc-6384and8more-tonemapped-jpg",
            "src": "/static/_gallery/albums/iceland-2015/1600x1101_dsc-6384and8more-tonemapped-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2015/1024x705_dsc-6384and8more-tonemapped-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2015/1600x1101_dsc-6384and8more-tonemapped-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2015/500x344_dsc-6384and8more-tonemapped-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2015/800x550_dsc-6384and8more-tonemapped-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2015/500x344_dsc-6384and8more-tonemapped-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1356,
            "name": "DSC-6880And8more-tonemapped2.jpg",
            "slug": "dsc-6880and8more-tonemapped2-jpg",
            "src": "/static/_gallery/albums/iceland-2015/1600x1059_dsc-6880and8more-tonemapped2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2015/1024x678_dsc-6880and8more-tonemapped2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2015/1600x1059_dsc-6880and8more-tonemapped2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2015/500x331_dsc-6880and8more-tonemapped2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2015/800x529_dsc-6880and8more-tonemapped2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2015/500x331_dsc-6880and8more-tonemapped2-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1356,
            "name": "DSC-6916And8more-tonemapped.jpg",
            "slug": "dsc-6916and8more-tonemapped-jpg",
            "src": "/static/_gallery/albums/iceland-2015/1600x1059_dsc-6916and8more-tonemapped-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2015/1024x678_dsc-6916and8more-tonemapped-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2015/1600x1059_dsc-6916and8more-tonemapped-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2015/500x331_dsc-6916and8more-tonemapped-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2015/800x529_dsc-6916and8more-tonemapped-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2015/500x331_dsc-6916and8more-tonemapped-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1356,
            "name": "DSC-7009-10-11-tonemapped.jpg",
            "slug": "dsc-7009-10-11-tonemapped-jpg",
            "src": "/static/_gallery/albums/iceland-2015/1600x1059_dsc-7009-10-11-tonemapped-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2015/1024x678_dsc-7009-10-11-tonemapped-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2015/1600x1059_dsc-7009-10-11-tonemapped-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2015/500x331_dsc-7009-10-11-tonemapped-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2015/800x529_dsc-7009-10-11-tonemapped-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2015/500x331_dsc-7009-10-11-tonemapped-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1000,
            "name": "hallgrimskirkja.jpg",
            "slug": "hallgrimskirkja-jpg",
            "src": "/static/_gallery/albums/iceland-2015/1000x1000_hallgrimskirkja-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2015/1000x1000_hallgrimskirkja-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2015/1000x1000_hallgrimskirkja-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2015/500x500_hallgrimskirkja-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2015/800x800_hallgrimskirkja-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2015/500x500_hallgrimskirkja-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 1356,
            "name": "horse.jpg",
            "slug": "horse-jpg",
            "src": "/static/_gallery/albums/iceland-2015/1600x1059_horse-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2015/1024x678_horse-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2015/1600x1059_horse-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2015/500x331_horse-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2015/800x529_horse-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2015/500x331_horse-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 848,
            "name": "northernlights.jpg",
            "slug": "northernlights-jpg",
            "src": "/static/_gallery/albums/iceland-2015/1600x662_northernlights-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/iceland-2015/1024x424_northernlights-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/iceland-2015/1600x662_northernlights-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/iceland-2015/500x207_northernlights-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/iceland-2015/800x331_northernlights-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/iceland-2015/500x207_northernlights-jpg.jpg",
            "width": 2048
         }
      ],
      "slug": "iceland-2015",
      "src": "/static/_gallery/albums/iceland-2015/500x331_dsc-6022-3-4-tonemapped-jpg.jpg"
   },
   "la-gomera": {
      "name": "la-gomera",
      "photos": [
         {
            "faces": [],
            "height": 1367,
            "name": "DSC-7551.jpg",
            "slug": "dsc-7551-jpg",
            "src": "/static/_gallery/albums/la-gomera/1600x1067_dsc-7551-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/la-gomera/1024x683_dsc-7551-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/la-gomera/1600x1067_dsc-7551-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/la-gomera/500x333_dsc-7551-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/la-gomera/800x533_dsc-7551-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/la-gomera/500x333_dsc-7551-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 2048,
            "name": "DSC-7597.jpg",
            "slug": "dsc-7597-jpg",
            "src": "/static/_gallery/albums/la-gomera/1367x2048_dsc-7597-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/la-gomera/1024x1534_dsc-7597-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/la-gomera/1367x2048_dsc-7597-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/la-gomera/500x749_dsc-7597-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/la-gomera/800x1198_dsc-7597-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/la-gomera/500x749_dsc-7597-jpg.jpg",
            "width": 1367
         },
         {
            "faces": [],
            "height": 1367,
            "name": "DSC-7611.jpg",
            "slug": "dsc-7611-jpg",
            "src": "/static/_gallery/albums/la-gomera/1600x1067_dsc-7611-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/la-gomera/1024x683_dsc-7611-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/la-gomera/1600x1067_dsc-7611-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/la-gomera/500x333_dsc-7611-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/la-gomera/800x533_dsc-7611-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/la-gomera/500x333_dsc-7611-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1367,
            "name": "DSC-7639.jpg",
            "slug": "dsc-7639-jpg",
            "src": "/static/_gallery/albums/la-gomera/1600x1067_dsc-7639-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/la-gomera/1024x683_dsc-7639-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/la-gomera/1600x1067_dsc-7639-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/la-gomera/500x333_dsc-7639-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/la-gomera/800x533_dsc-7639-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/la-gomera/500x333_dsc-7639-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1367,
            "name": "DSC-7762.jpg",
            "slug": "dsc-7762-jpg",
            "src": "/static/_gallery/albums/la-gomera/1600x1067_dsc-7762-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/la-gomera/1024x683_dsc-7762-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/la-gomera/1600x1067_dsc-7762-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/la-gomera/500x333_dsc-7762-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/la-gomera/800x533_dsc-7762-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/la-gomera/500x333_dsc-7762-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1367,
            "name": "DSC-7781.jpg",
            "slug": "dsc-7781-jpg",
            "src": "/static/_gallery/albums/la-gomera/1600x1067_dsc-7781-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/la-gomera/1024x683_dsc-7781-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/la-gomera/1600x1067_dsc-7781-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/la-gomera/500x333_dsc-7781-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/la-gomera/800x533_dsc-7781-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/la-gomera/500x333_dsc-7781-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1367,
            "name": "DSC-7827.jpg",
            "slug": "dsc-7827-jpg",
            "src": "/static/_gallery/albums/la-gomera/1600x1067_dsc-7827-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/la-gomera/1024x683_dsc-7827-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/la-gomera/1600x1067_dsc-7827-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/la-gomera/500x333_dsc-7827-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/la-gomera/800x533_dsc-7827-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/la-gomera/500x333_dsc-7827-jpg.jpg",
            "width": 2048
         }
      ],
      "slug": "la-gomera",
      "src": "/static/_gallery/albums/la-gomera/500x333_dsc-7551-jpg.jpg"
   },
   "panoramen": {
      "name": "panoramen",
      "photos": [
         {
            "faces": [],
            "height": 767,
            "name": "20130201124601.jpg",
            "slug": "20130201124601-jpg",
            "src": "/static/_gallery/albums/panoramen/1600x299_20130201124601-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/panoramen/1024x191_20130201124601-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/panoramen/1600x299_20130201124601-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/panoramen/500x93_20130201124601-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/panoramen/800x149_20130201124601-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/panoramen/500x93_20130201124601-jpg.jpg",
            "width": 4095
         },
         {
            "faces": [],
            "height": 847,
            "name": "20130201124838.jpg",
            "slug": "20130201124838-jpg",
            "src": "/static/_gallery/albums/panoramen/1600x330_20130201124838-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/panoramen/1024x211_20130201124838-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/panoramen/1600x330_20130201124838-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/panoramen/500x103_20130201124838-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/panoramen/800x165_20130201124838-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/panoramen/500x103_20130201124838-jpg.jpg",
            "width": 4096
         },
         {
            "faces": [],
            "height": 742,
            "name": "20130201125248.jpg",
            "slug": "20130201125248-jpg",
            "src": "/static/_gallery/albums/panoramen/1600x289_20130201125248-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/panoramen/1024x185_20130201125248-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/panoramen/1600x289_20130201125248-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/panoramen/500x90_20130201125248-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/panoramen/800x144_20130201125248-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/panoramen/500x90_20130201125248-jpg.jpg",
            "width": 4095
         },
         {
            "faces": [],
            "height": 697,
            "name": "20130201160718.jpg",
            "slug": "20130201160718-jpg",
            "src": "/static/_gallery/albums/panoramen/1600x272_20130201160718-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/panoramen/1024x174_20130201160718-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/panoramen/1600x272_20130201160718-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/panoramen/500x85_20130201160718-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/panoramen/800x136_20130201160718-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/panoramen/500x85_20130201160718-jpg.jpg",
            "width": 4096
         },
         {
            "faces": [],
            "height": 849,
            "name": "DSC-0592-DSC-0601.jpg",
            "slug": "dsc-0592-dsc-0601-jpg",
            "src": "/static/_gallery/albums/panoramen/1600x331_dsc-0592-dsc-0601-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/panoramen/1024x212_dsc-0592-dsc-0601-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/panoramen/1600x331_dsc-0592-dsc-0601-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/panoramen/500x103_dsc-0592-dsc-0601-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/panoramen/800x165_dsc-0592-dsc-0601-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/panoramen/500x103_dsc-0592-dsc-0601-jpg.jpg",
            "width": 4095
         },
         {
            "faces": [],
            "height": 1163,
            "name": "DSC-7564-DSC-7573.jpg",
            "slug": "dsc-7564-dsc-7573-jpg",
            "src": "/static/_gallery/albums/panoramen/1600x454_dsc-7564-dsc-7573-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/panoramen/1024x290_dsc-7564-dsc-7573-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/panoramen/1600x454_dsc-7564-dsc-7573-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/panoramen/500x141_dsc-7564-dsc-7573-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/panoramen/800x227_dsc-7564-dsc-7573-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/panoramen/500x141_dsc-7564-dsc-7573-jpg.jpg",
            "width": 4096
         }
      ],
      "slug": "panoramen",
      "src": "/static/_gallery/albums/panoramen/500x93_20130201124601-jpg.jpg"
   },
   "pinakothek-der-moderne": {
      "name": "pinakothek-der-moderne",
      "photos": [
         {
            "faces": [],
            "height": 2048,
            "name": "DSC-2119.jpg",
            "slug": "dsc-2119-jpg",
            "src": "/static/_gallery/albums/pinakothek-der-moderne/1356x2048_dsc-2119-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1024x1546_dsc-2119-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1356x2048_dsc-2119-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/499x755_dsc-2119-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/800x1208_dsc-2119-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/pinakothek-der-moderne/499x755_dsc-2119-jpg.jpg",
            "width": 1356
         },
         {
            "faces": [],
            "height": 1356,
            "name": "DSC-2121.jpg",
            "slug": "dsc-2121-jpg",
            "src": "/static/_gallery/albums/pinakothek-der-moderne/1600x1059_dsc-2121-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1024x678_dsc-2121-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1600x1059_dsc-2121-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/500x331_dsc-2121-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/800x529_dsc-2121-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/pinakothek-der-moderne/500x331_dsc-2121-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1356,
            "name": "DSC-2122.jpg",
            "slug": "dsc-2122-jpg",
            "src": "/static/_gallery/albums/pinakothek-der-moderne/1600x1059_dsc-2122-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1024x678_dsc-2122-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1600x1059_dsc-2122-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/500x331_dsc-2122-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/800x529_dsc-2122-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/pinakothek-der-moderne/500x331_dsc-2122-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 2048,
            "name": "DSC-2129.jpg",
            "slug": "dsc-2129-jpg",
            "src": "/static/_gallery/albums/pinakothek-der-moderne/1356x2048_dsc-2129-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1024x1546_dsc-2129-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1356x2048_dsc-2129-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/499x755_dsc-2129-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/800x1208_dsc-2129-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/pinakothek-der-moderne/499x755_dsc-2129-jpg.jpg",
            "width": 1356
         },
         {
            "faces": [],
            "height": 2048,
            "name": "DSC-2130.jpg",
            "slug": "dsc-2130-jpg",
            "src": "/static/_gallery/albums/pinakothek-der-moderne/1356x2048_dsc-2130-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1024x1546_dsc-2130-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1356x2048_dsc-2130-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/499x755_dsc-2130-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/800x1208_dsc-2130-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/pinakothek-der-moderne/499x755_dsc-2130-jpg.jpg",
            "width": 1356
         },
         {
            "faces": [],
            "height": 1356,
            "name": "DSC-2131.jpg",
            "slug": "dsc-2131-jpg",
            "src": "/static/_gallery/albums/pinakothek-der-moderne/1600x1059_dsc-2131-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1024x678_dsc-2131-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1600x1059_dsc-2131-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/500x331_dsc-2131-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/800x529_dsc-2131-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/pinakothek-der-moderne/500x331_dsc-2131-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 2048,
            "name": "DSC-2133.jpg",
            "slug": "dsc-2133-jpg",
            "src": "/static/_gallery/albums/pinakothek-der-moderne/1356x2048_dsc-2133-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1024x1546_dsc-2133-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1356x2048_dsc-2133-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/499x755_dsc-2133-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/800x1208_dsc-2133-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/pinakothek-der-moderne/499x755_dsc-2133-jpg.jpg",
            "width": 1356
         },
         {
            "faces": [],
            "height": 2048,
            "name": "DSC-2135.jpg",
            "slug": "dsc-2135-jpg",
            "src": "/static/_gallery/albums/pinakothek-der-moderne/1356x2048_dsc-2135-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1024x1546_dsc-2135-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1356x2048_dsc-2135-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/499x755_dsc-2135-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/800x1208_dsc-2135-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/pinakothek-der-moderne/499x755_dsc-2135-jpg.jpg",
            "width": 1356
         },
         {
            "faces": [],
            "height": 1356,
            "name": "DSC-2139.jpg",
            "slug": "dsc-2139-jpg",
            "src": "/static/_gallery/albums/pinakothek-der-moderne/1600x1059_dsc-2139-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1024x678_dsc-2139-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1600x1059_dsc-2139-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/500x331_dsc-2139-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/800x529_dsc-2139-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/pinakothek-der-moderne/500x331_dsc-2139-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 2048,
            "name": "DSC-2151.jpg",
            "slug": "dsc-2151-jpg",
            "src": "/static/_gallery/albums/pinakothek-der-moderne/1356x2048_dsc-2151-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1024x1546_dsc-2151-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1356x2048_dsc-2151-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/499x755_dsc-2151-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/800x1208_dsc-2151-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/pinakothek-der-moderne/499x755_dsc-2151-jpg.jpg",
            "width": 1356
         },
         {
            "faces": [],
            "height": 1356,
            "name": "DSC-2153.jpg",
            "slug": "dsc-2153-jpg",
            "src": "/static/_gallery/albums/pinakothek-der-moderne/1600x1059_dsc-2153-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1024x678_dsc-2153-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1600x1059_dsc-2153-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/500x331_dsc-2153-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/800x529_dsc-2153-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/pinakothek-der-moderne/500x331_dsc-2153-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1356,
            "name": "DSC-2155.jpg",
            "slug": "dsc-2155-jpg",
            "src": "/static/_gallery/albums/pinakothek-der-moderne/1600x1059_dsc-2155-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1024x678_dsc-2155-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/1600x1059_dsc-2155-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/500x331_dsc-2155-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/pinakothek-der-moderne/800x529_dsc-2155-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/pinakothek-der-moderne/500x331_dsc-2155-jpg.jpg",
            "width": 2048
         }
      ],
      "slug": "pinakothek-der-moderne",
      "src": "/static/_gallery/albums/pinakothek-der-moderne/499x755_dsc-2119-jpg.jpg"
   },
   "reykjavik-2013": {
      "name": "reykjavik-2013",
      "photos": [
         {
            "faces": [],
            "height": 1356,
            "name": "20130131174211.jpg",
            "slug": "20130131174211-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1600x1059_20130131174211-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x678_20130131174211-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1600x1059_20130131174211-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/500x331_20130131174211-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x529_20130131174211-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/500x331_20130131174211-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 2048,
            "name": "20130131182725.jpg",
            "slug": "20130131182725-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1356x2048_20130131182725-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x1546_20130131182725-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1356x2048_20130131182725-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/499x755_20130131182725-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x1208_20130131182725-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/499x755_20130131182725-jpg.jpg",
            "width": 1356
         },
         {
            "faces": [],
            "height": 1356,
            "name": "20130131183007.jpg",
            "slug": "20130131183007-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1600x1059_20130131183007-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x678_20130131183007-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1600x1059_20130131183007-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/500x331_20130131183007-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x529_20130131183007-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/500x331_20130131183007-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 2048,
            "name": "20130131183031.jpg",
            "slug": "20130131183031-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1365x2048_20130131183031-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x1536_20130131183031-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1365x2048_20130131183031-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/500x750_20130131183031-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x1200_20130131183031-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/500x750_20130131183031-jpg.jpg",
            "width": 1365
         },
         {
            "faces": [],
            "height": 1356,
            "name": "20130131183206.jpg",
            "slug": "20130131183206-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1600x1059_20130131183206-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x678_20130131183206-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1600x1059_20130131183206-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/500x331_20130131183206-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x529_20130131183206-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/500x331_20130131183206-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1084,
            "name": "20130131183846.jpg",
            "slug": "20130131183846-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1600x846_20130131183846-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x542_20130131183846-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1600x846_20130131183846-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/500x264_20130131183846-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x423_20130131183846-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/500x264_20130131183846-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 2048,
            "name": "20130131184225.jpg",
            "slug": "20130131184225-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1356x2048_20130131184225-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x1546_20130131184225-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1356x2048_20130131184225-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/499x755_20130131184225-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x1208_20130131184225-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/499x755_20130131184225-jpg.jpg",
            "width": 1356
         },
         {
            "faces": [],
            "height": 2048,
            "name": "20130131190158.jpg",
            "slug": "20130131190158-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1356x2048_20130131190158-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x1546_20130131190158-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1356x2048_20130131190158-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/499x755_20130131190158-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x1208_20130131190158-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/499x755_20130131190158-jpg.jpg",
            "width": 1356
         },
         {
            "faces": [],
            "height": 1365,
            "name": "20130131195632.jpg",
            "slug": "20130131195632-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1600x1066_20130131195632-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x682_20130131195632-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1600x1066_20130131195632-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/500x333_20130131195632-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x533_20130131195632-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/500x333_20130131195632-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1356,
            "name": "20130201104034.jpg",
            "slug": "20130201104034-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1600x1059_20130201104034-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x678_20130201104034-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1600x1059_20130201104034-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/500x331_20130201104034-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x529_20130201104034-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/500x331_20130201104034-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1356,
            "name": "20130201105908.jpg",
            "slug": "20130201105908-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1600x1059_20130201105908-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x678_20130201105908-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1600x1059_20130201105908-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/500x331_20130201105908-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x529_20130201105908-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/500x331_20130201105908-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 2048,
            "name": "20130201121425.jpg",
            "slug": "20130201121425-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1356x2048_20130201121425-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x1546_20130201121425-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1356x2048_20130201121425-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/499x755_20130201121425-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x1208_20130201121425-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/499x755_20130201121425-jpg.jpg",
            "width": 1356
         },
         {
            "faces": [],
            "height": 1395,
            "name": "20130201122905.jpg",
            "slug": "20130201122905-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1600x1089_20130201122905-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x697_20130201122905-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1600x1089_20130201122905-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/500x340_20130201122905-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x544_20130201122905-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/500x340_20130201122905-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 2048,
            "name": "20130201123118.jpg",
            "slug": "20130201123118-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1356x2048_20130201123118-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x1546_20130201123118-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1356x2048_20130201123118-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/499x755_20130201123118-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x1208_20130201123118-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/499x755_20130201123118-jpg.jpg",
            "width": 1356
         },
         {
            "faces": [],
            "height": 383,
            "name": "20130201124601.jpg",
            "slug": "20130201124601-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1600x299_20130201124601-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x191_20130201124601-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1600x299_20130201124601-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/499x93_20130201124601-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x149_20130201124601-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/499x93_20130201124601-jpg.jpg",
            "width": 2047
         },
         {
            "faces": [],
            "height": 2048,
            "name": "20130201124754.jpg",
            "slug": "20130201124754-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1356x2048_20130201124754-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x1546_20130201124754-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1356x2048_20130201124754-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/499x755_20130201124754-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x1208_20130201124754-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/499x755_20130201124754-jpg.jpg",
            "width": 1356
         },
         {
            "faces": [],
            "height": 423,
            "name": "20130201124838.jpg",
            "slug": "20130201124838-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1600x330_20130201124838-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x211_20130201124838-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1600x330_20130201124838-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/500x103_20130201124838-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x165_20130201124838-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/500x103_20130201124838-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1356,
            "name": "20130201125141.jpg",
            "slug": "20130201125141-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1600x1059_20130201125141-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x678_20130201125141-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1600x1059_20130201125141-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/500x331_20130201125141-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x529_20130201125141-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/500x331_20130201125141-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 371,
            "name": "20130201125248.jpg",
            "slug": "20130201125248-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1600x289_20130201125248-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x185_20130201125248-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1600x289_20130201125248-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/499x90_20130201125248-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x144_20130201125248-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/499x90_20130201125248-jpg.jpg",
            "width": 2047
         },
         {
            "faces": [],
            "height": 2048,
            "name": "20130201130213.jpg",
            "slug": "20130201130213-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1356x2048_20130201130213-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x1546_20130201130213-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1356x2048_20130201130213-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/499x755_20130201130213-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x1208_20130201130213-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/499x755_20130201130213-jpg.jpg",
            "width": 1356
         },
         {
            "faces": [],
            "height": 1356,
            "name": "20130201130536.jpg",
            "slug": "20130201130536-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1600x1059_20130201130536-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x678_20130201130536-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1600x1059_20130201130536-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/500x331_20130201130536-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x529_20130201130536-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/500x331_20130201130536-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 348,
            "name": "20130201160718.jpg",
            "slug": "20130201160718-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1600x271_20130201160718-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x174_20130201160718-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1600x271_20130201160718-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/500x84_20130201160718-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x135_20130201160718-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/500x84_20130201160718-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1356,
            "name": "20130201160759.jpg",
            "slug": "20130201160759-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1600x1059_20130201160759-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x678_20130201160759-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1600x1059_20130201160759-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/500x331_20130201160759-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x529_20130201160759-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/500x331_20130201160759-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1356,
            "name": "20130201160835.jpg",
            "slug": "20130201160835-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1600x1059_20130201160835-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x678_20130201160835-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1600x1059_20130201160835-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/500x331_20130201160835-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x529_20130201160835-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/500x331_20130201160835-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 1536,
            "name": "20130202133945.jpg",
            "slug": "20130202133945-jpg",
            "src": "/static/_gallery/albums/reykjavik-2013/1600x1200_20130202133945-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/reykjavik-2013/1024x768_20130202133945-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/reykjavik-2013/1600x1200_20130202133945-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/reykjavik-2013/500x375_20130202133945-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/reykjavik-2013/800x600_20130202133945-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/reykjavik-2013/500x375_20130202133945-jpg.jpg",
            "width": 2048
         }
      ],
      "slug": "reykjavik-2013",
      "src": "/static/_gallery/albums/reykjavik-2013/500x331_20130131174211-jpg.jpg"
   }
};